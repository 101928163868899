import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-653cdfb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["hidden"]
const _hoisted_3 = { class: "column-label" }
const _hoisted_4 = {
  key: 1,
  class: "column-description"
}
const _hoisted_5 = ["hidden"]
const _hoisted_6 = {
  key: 2,
  class: "add-new-field-btn"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['column', $setup.selected ? 'selected' : $setup.hovered ? 'hovered' : '']),
    title: $props.column.df.fieldname,
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.store.form.selected_field = $props.column.df), ["stop"])),
    onMouseover: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.hovered = true), ["stop"])),
    onMouseout: _cache[3] || (_cache[3] = _withModifiers($event => ($setup.hovered = false), ["stop"]))
  }, [
    ($props.column.df.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "column-header",
          hidden: !$props.column.df.label && $setup.store.read_only
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.__($props.column.df.label)), 1 /* TEXT */)
          ])
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($props.column.df.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.__($props.column.df.description)), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["draggable"], {
      class: "column-container",
      modelValue: $props.column.fields,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.column.fields) = $event)),
      group: "fields",
      delay: $setup.is_touch_screen_device() ? 200 : 0,
      animation: 200,
      easing: $setup.store.get_animation,
      "item-key": "id",
      disabled: $setup.store.read_only
    }, {
      item: _withCtx(({ element }) => [
        _createVNode($setup["Field"], {
          column: $props.column,
          field: element,
          "data-is-user-generated": $setup.store.is_user_generated_field(element)
        }, null, 8 /* PROPS */, ["column", "field", "data-is-user-generated"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "delay", "easing", "disabled"]),
    _createElementVNode("div", {
      class: "empty-column",
      hidden: $setup.store.read_only
    }, [
      _createVNode($setup["AddFieldButton"], { column: $props.column }, null, 8 /* PROPS */, ["column"])
    ], 8 /* PROPS */, _hoisted_5),
    ($props.column.fields.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode($setup["AddFieldButton"], {
            field: $props.column.fields[$props.column.fields.length - 1],
            column: $props.column
          }, null, 8 /* PROPS */, ["field", "column"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}